<template src="./receivers.htm">
 


</template>

<script>
import { mapState } from 'vuex';
import Footer from '@/layouts/footer/Footer.vue';
import SubHeader from '@/layouts/sub_header/Subheader.vue';

export default {
  name: "Receivers",
  components: {
      'footer-component': Footer,
      'sub-header': SubHeader,
  },
  data: () => ({
    valid: true,
    name: '',
    selected: [],
    ids: [],
    dialog: false,
    dialog2: false,
    Edit_receiver: false,
    view_table: true,
    view_info: false,
    Serial_Number: '',
    Identification: '',
    Serial_No: '',
    locations: [],
    editMode: false,
    is_admin: '',
    initForm: {
      serial: '',
      location: '',
      type: '',
      id:'',
      user_id:''
    },
    Serial_No_Rules: [
          v => !!v || 'Serial Number is required',
        ],      
        
    Location_Name: '',
    Location_Name_Rules: [
          v => !!v || 'Location Name is required',
        ],
    IdentificationRules: [
          v => !!v || 'This Field is Required',
        ],
    users: [],
    overlay: true,
    table_header: [
      { 
        text: 'Serial Number',
        align: 'start',
        sortable: false,
        value: 'serial_no',
      },
      { text: 'Location', value: 'location_name' },      
    ],
  userprofile: [
      { title: "My Profile" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" }
    ]
  }),
  computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
  watch: {
      dialog (val) {
        val
        //val || this.close()
      },
    },
  methods: {
      handleClick (row){
        console.log("row click")
      },
      delete_selected (){
        var result = confirm("Are you sure you want to delete?");
        if (result==true) {
         this.ids = this.selected.map(receiver => {
            return receiver.id;
          });

          this.$api
            .post('/receiver/delete', {ids: this.ids})
            .then(res => {
                this.$toast.open({
                    message:  res.data.msg,
                    type: 'success',
                    position: 'bottom'

                }),
                this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
        }
      },
      editReceiver(val) {
        this.editMode = true;
        this.dialog = true;

        this.initForm.location = val.location_id;
        this.initForm.id = val.id;
        this.initForm.serial = val.serial;
        this.initForm.type = val.type;
      },
      closeDialog () {
          this.$refs.form.reset(),
          this.dialog=!this.dialog
      },
      submit() {
        if(this.$refs.form.validate()){
           this.$api
            .post(`/receiver`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
              this.closeDialog();
              this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            })
          }
      },
      getLocation() {
        this.$api
          .get('/location')
            .then(res => {
                this.locations = res.data.data;
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
      },
      search() {
        this.overlay = true;
        this.$api
         .get('/receiver/search')
            .then(res => {
                this.users = res.data.data;
                this.overlay = false;
            })
            .catch(err => {
                this.overlay = false;
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
      },
      AddForm (){
        this.editMode = false;
      },
      save() {
        if(this.$refs.form.validate()) {

          this.$api
            .put(`/receiver/${this.initForm.id}`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
              this.closeDialog();
              this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            })
        }
      },
      init() {
        this.search();
        this.getLocation();
      }
  },
  created(){
      this.overlay = true;
  },
  mounted() {

    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }

    this.name = this.user.name;
    this.is_admin = this.user.is_admin;
    this.initForm.user_id = this.user.id;
    this.init();
  },
};
</script>