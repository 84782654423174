<template>
  <div class="d-flex justify-space-between">
      <div>
          <h1 class="text-blue">{{page_title}}</h1>
      </div>
      <div>
          <p class="d-inline-block">
              <span class="align-middle pr-2"><img src="@/assets/images/Avatars.png" width="30"></span>
              Logged in as {{name}}
          </p>
          <v-menu bottom left transition="scale-transition">
          <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
              <v-icon class="text-blue">mdi-arrow-down-drop-circle</v-icon>
              </v-btn>
          </template>

          <v-list>
              <v-list-item>
                <v-list-item-title > <a href="/dashboard/Change-Password/ChangePassword">Change Password</a></v-list-item-title>
              </v-list-item>
               <v-list-item v-if="is_admin">
                <v-list-item-title > <a href="#" @click="admin">Admin</a></v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title > <a href="#" @click="logout">Logout</a></v-list-item-title>
              </v-list-item>
          </v-list>
          </v-menu>
      </div>
      
  </div>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",

  components: {},

  props: {
    page_title: {
      type: String,
    },
    name: {
      type: String,
    },
    is_admin: {
      type: Boolean,
    }
  },
  data: () => ({

    href() {
      return undefined;
    }
  }),

  computed: {
    ...mapState(["Sidebar_drawer"],
    {
      user: state => state.auth.user,
      isAuthenticated: state => state.auth.isAuthenticated
    })
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER"
    }),
    logout() {
        console.log('test');
        this.$store.dispatch('auth/logout');
    },
    change_password() {
        this.$store.dispatch('auth/change-password');
    },
    admin() {
        location.href = '/admin'
    }
  },
  mounted() {
  },
};
</script>