<template>
  <v-footer>
      <a class="text-color-semicontent2 text-gray">Need help? Contact support now.</a>
    <div>
      <a class="align-middle pr-2 text-lapis" href="mailto:konikosystems.inc@gmail.com"><span>
        <img src="@/assets/icon/Email-icon.png" width="16">
      </span>
      konikosystems.inc@gmail.com</a>
    </div>
    <div>
      <a class="align-middle pr-2 text-lapis" href="tel:09177907949"><span><img src="@/assets/icon/Call-icon.png" width="16">
      </span>
      09177907949</a>
    </div>
  </v-footer>
</template>
<script>
export default {
    name: "Footer",
}
</script>
